"use client";
import { track } from "@amplitude/analytics-react-native";
import { useEffect } from "react";
import { View } from "react-native";
import { usePathname } from "solito/navigation";
import { amp } from "src/api/amplitude";
import Background404Image from "src/components/Background404Image";
import EscapeButton from "src/components/EscapeButton";
import RoundedButton from "src/components/RoundedButton";
import useEscape from "src/hooks/useEscape";
import { color } from "src/styles";
import { paddingLarge } from "src/styles/spacing";
import { Text } from "src/swsh-native";

const General404 = ({ itemText = "page" }: { itemText?: string }) => {
	const pathname = usePathname();
	const { escape } = useEscape();
	useEffect(() => {
		track(amp.notFound.general, {
			path: pathname,
			itemText: itemText,
		});
	}, [itemText, pathname]);

	return (
		<View
			style={{
				flex: 1,
				alignItems: "center",
				backgroundColor: color.Background.Dark,
			}}
		>
			<Background404Image
				style={{
					maxHeight: "80%",
					width: "100%",
				}}
			/>
			<EscapeButton />
			<Text semibold large>
				Whoops!
			</Text>
			<Text>
				This {itemText.toLowerCase()} {`doesn't`} seem to exist
			</Text>
			<RoundedButton
				containerStyle={{
					marginTop: paddingLarge,
				}}
				onPress={escape}
				sizeVariant="Large"
			>
				Go back
			</RoundedButton>
		</View>
	);
};
export default General404;
