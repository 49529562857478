import "./main.css";

import type { ReactNode } from "react";
import { SentryLazyInitComponent } from "src/api/sentry/sentryLazyInit.web";
import GlobalWebBackgroundManager from "./_/components/GlobalWebBackgroundManager";
import { StylesProvider } from "./_/components/styles-provider";
import LazyMotion from "./_/helpers/framer-motion/LazyMotion";

const RootLayout = ({ children }: { children: ReactNode }) => {
	return (
		<LazyMotion>
			<SentryLazyInitComponent />
			<StylesProvider>{children}</StylesProvider>
			<GlobalWebBackgroundManager />
		</LazyMotion>
	);
};

export default RootLayout;
