import { useModalContext } from "src/modals/contexts/ModalContext";
import useRouter from "../useRouter";
import { UseEscapeResponse } from "./types";

const useEscape = (): UseEscapeResponse => {
	const router = useRouter();
	const modalContext = useModalContext();
	return {
		nativeCanGoBack: false,
		escape: () => {
			if (modalContext) {
				modalContext.closeSelf();
			} else {
				router.back();
			}
		},
	};
};
export default useEscape;
