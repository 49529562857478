import Image from "next/image";
import { StyleProp, View, ViewStyle } from "react-native";
import image_404_background_large from "src/assets/images/404_background_large.png";

const Background404Image = ({ style }: { style?: StyleProp<ViewStyle> }) => {
	return (
		<View
			style={[
				{
					flex: 1,
				},
				style,
			]}
		>
			<Image
				alt="404 Image"
				src={image_404_background_large}
				layout="fill"
				objectFit="cover"
			/>
		</View>
	);
};
export default Background404Image;
